<template>
  <div>
    <h2 class="main-title">
      {{ $t('settings') }}
    </h2>
    <v-form class="form pl-0 pr-0"
            v-model="validForm"
            ref="form">
      <h3 class="mb-2">
        {{ $t('profile') }}
      </h3>
      <div class="driver-block">
        <v-row class="mb-1">
            <v-col cols="12" sm="12" class="pt-0 pb-0 mt-4">
              <div class="form-field">
                <div class="form-field__label">
                  {{ $t('fieldProfilePhotoLabel') }}
                </div>
                <div class="form-field__body">
                  <ImageFile
                    image-id="avatarLink"
                    :image="holaForm.avatarLink"
                    v-model="holaForm.avatarLink">
                  </ImageFile>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0 pb-0">
              <div class="form-field">
                <div class="form-field__label">
                  {{ $t('fieldFirstNameLabel') }}
                </div>
                <div class="form-field__body">
                  <v-text-field
                    v-model="holaForm.firstName"
                    :error-messages="serverErrors?.firstName"
                    class="mt-0 pt-0"
                    prepend-inner-icon="mdi-pencil"
                    :rules="required"
                    name="firstName"
                    type="text"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0 pb-0">
              <div class="form-field">
                <div class="form-field__label">
                  {{ $t('fieldLastNameLabel') }}
                </div>
                <div class="form-field__body">
                  <v-text-field
                    v-model="holaForm.lastName"
                    :rules="required"
                    :error-messages="serverErrors?.lastName"
                    class="mt-0 pt-0"
                    prepend-inner-icon="mdi-pencil"
                    name="lastName"
                    type="text"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0 mb-2">
              <div class="form-field">
                <div class="form-field__label mb-1">
                  {{ $t('fieldPhoneLabel') }}
                </div>
                <div class="form-field__body">
                  <PhoneInput
                    :value="holaForm.phone"
                    :errors="phoneErrors"
                    @on-update="holaForm.phone = $event"
                    @valid="validPhone = $event"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0 pb-0">
              <div class="form-field mb-5">
                <div class="form-field__label">
                  {{ $t('fieldEmailLabel') }}
                </div>
                <div class="form-field__body">
                  <v-text-field
                    v-model.trim="holaForm.email"
                    :error-messages="serverErrors?.email"
                    disabled
                    class="mt-0 pt-0"
                    :rules="emailRules"
                    prepend-inner-icon="mdi-pencil"
                    name="email"
                    type="text"
                  />
                  <small style="font-size: 12px; line-height: 1.1; display: block; color: #999">
                    {{ $t('emailWarning') }}
                  </small>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0 pb-0">
              <div class="form-field">
                <div class="form-field__label">
                  {{ $t('fieldNicknameLabel') }}
                </div>
                <div class="form-field__body">
                  <v-text-field
                    v-model="holaForm.nickname"
                    :error-messages="serverErrors?.nickname"
                    class="mt-0 pt-0"
                    disabled
                    prepend-inner-icon="mdi-pencil"
                    name="nickname"
                    :rules="required"
                    type="text"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
              <div class="form-field">
                <div class="form-field__label">
                  {{ $t('fieldBirthLabel') }}
                </div>
                <div class="form-field__body datepicker">
                  <v-menu
                    v-model="openDatepicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="holaForm.birthDate"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        class="mt-0 pt-0"
                        :rules="required"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="holaForm.birthDate"
                      min="1940-01-01"
                      max="2013-01-01"
                      @input="openDatepicker = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0 pb-0">
              <div class="form-field">
                <div class="form-field__label">
                  {{ $t('fieldDriverTypeLabel') }}
                </div>
                <div class="form-field__body">
                  <v-select
                    v-model="form.driverType"
                    :items="$options.driverTypes()"
                    :rules="requiredSelect"
                    append-icon="mdi-chevron-down"
                    class="mt-0 pt-0 m-select"
                    item-text="labelKey"
                    item-value="id"
                    name="driverTypes"
                  ></v-select>
                </div>
              </div>
            </v-col>
          </v-row>
      </div>
      <h3 class="mb-2">
        {{ $t('license') }}
      </h3>
      <div class="driver-block">
        <v-row class="mb-1">
          <v-col cols="12" sm="12" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldLicenseNumberLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="form.licenseNumber"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  name="licenseNumber"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('licensePhotoFront') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.licensePhotoFront"
                  imageFullscreenMode
                  image-id="licensePhotoFront"
                  :image="form.licensePhotoFront">
                </ImageFile>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('licensePhotoBack') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.licensePhotoBack"
                  imageFullscreenMode
                  image-id="licensePhotoBack"
                  :image="form.licensePhotoBack">
                </ImageFile>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('licenseIdConfirmation') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.licensePhotoNFace"
                  imageFullscreenMode
                  image-id="licensePhotoNFace"
                  :image="form.licensePhotoNFace">
                </ImageFile>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <h3 class="mb-2">
        {{ $t('stepIdentification') }}
      </h3>
      <div class="driver-block">
        <v-row>
          <v-col cols="12" sm="12" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{  $t('fieldIdNumberLabel')}}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="form.idNumber"
                  class="mt-0 pt-0"
                  :rules="required"
                  prepend-inner-icon="mdi-pencil"
                  name="idNumber"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldPhotoFrontLabel') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.idPhotoFront"
                  image-id="idPhotoFront"
                  imageFullscreenMode
                  :image="form.idPhotoFront">
                </ImageFile>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldPhotoBackLabel') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.idPhotoBack"
                  imageFullscreenMode
                  image-id="idPhotoBack"
                  :image="form.idPhotoBack">
                </ImageFile>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <h3 class="mb-2">
        {{ $t('stepVehicleInfo') }}
      </h3>
      <div class="driver-block">
        <v-row>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldLicensePlateNumberLabel') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="form.licensePlateNumber"
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  name="licensePlateNumber"
                  type="text"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldYearVehicleLabel') }}
              </div>
              <div class="form-field__body datepicker">
                <v-text-field
                  v-model="form.vehicleYear"
                  name="vehicleYear"
                  imageFullscreenMode
                  class="mt-0 pt-0"
                  prepend-inner-icon="mdi-pencil"
                  type="number"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('photoVehicleLicensePlate') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.vehiclePhoto"
                  imageFullscreenMode
                  image-id="vehiclePhoto"
                  :image="form.vehiclePhoto">
                </ImageFile>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('photoVehicleRegistrationCardFront') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.registrationCardPhotoFront"
                  imageFullscreenMode
                  image-id="registrationCardPhotoFront"
                  :image="form.registrationCardPhotoFront">
                </ImageFile>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('photoVehicleRegistrationCardBack') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.registrationCardPhotoBack"
                  image-id="registrationCardPhotoBack"
                  imageFullscreenMode
                  :image="form.registrationCardPhotoBack">
                </ImageFile>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <h3 class="mb-2">
        {{ $t('stepPoliceReport') }}
      </h3>
      <div class="driver-block">
        <v-row>
          <v-col cols="12" sm="12" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('backgroundCheck') }}
              </div>
              <div class="form-field__body">
                <ImageFile
                  v-model="form.backgroundCheck"
                  image-id="backgroundCheck"
                  imageFullscreenMode
                  :image="form.backgroundCheck">
                </ImageFile>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <h3 class="mb-2">
        {{ $t('stepAcceptance') }}
      </h3>
      <div class="driver-block">
        <v-row>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('countryDrivingFor') }}
              </div>
              <div class="form-field__body">
                <v-select
                  v-model="form.countryId"
                  class="mt-0 pt-0 m-select"
                  :items="countries"
                  item-value="id"
                  item-text="name"
                  append-icon="mdi-chevron-down"
                  :rules="required"
                  name="countryId"
                ></v-select>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <div class="form-field">
              <div class="form-field__label">
                {{ $t('fieldSignatureLabel') }}
              </div>
              <div class="form-field__body datepicker">
                <v-text-field
                  v-model="form.signature"
                  class="mt-0 pt-0"
                  :rules="required"
                  prepend-inner-icon="mdi-pencil"
                  name="signature"
                  type="text"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-center pb-9">
        <div>
          <v-btn
            type="submit"
            color="primary"
            large
            :loading="updatePhoneLoading || myProfileLoading || driverLoading || uploadMediaLoading"
            @click.prevent="submitForm"
          >
            {{ $t('update') }}
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import ImageFile from '@/components/features/ImageFile.vue'
import PhoneInput from '@/components/atoms/PhoneInput.vue'
import validation from '@/utils/validations.js'
import {mapActions, mapGetters} from "vuex";
import apiMedia from "@/api/media";

const driverTypes = function () {
  return [
    {
      id: 0,
      labelKey: 'Provide trips with my car'
    },
    {
      id: 1,
      labelKey: 'I want someone to drive my Vehicle'
    },
    {
      id: 2,
      labelKey: 'I want to drive but don\'t have a car'
    },
    {
      id: 3,
      labelKey: 'I want to deliver on Moto'
    },
    {
      id: 4,
      labelKey: 'I want to deliver by Bicycle'
    },
    {
      id: 5,
      labelKey: 'I want to deliver small freight delivery'
    },
    {
      id: 6,
      labelKey: 'I want to provide large freight delivery'
    },
    {
      id: 7,
      labelKey: 'I want to offer All terrain 4x4 rides'
    },
    {
      id: 8,
      labelKey: 'I want to offer groups small shuttle rides'
    },
    {
      id: 9,
      labelKey: 'I want to offer rides by moto'
    }
  ]
}

export default {
  driverTypes,
  mixins: [validation],
  components: {
    ImageFile,
    PhoneInput
  },
  props: {
    statuses: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingVerify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openDatepicker: false,
      validForm: false,
      uploadMediaLoading: false,
      validPhone: false,
      serverErrors: {},
      form: {
        driverType: '',
        licenseNumber: '',
        licensePhotoFront: '',
        licensePhotoBack: '',
        licensePhotoNFace: '',
        idNumber: '',
        idPhotoFront: '',
        idPhotoBack: '',
        licensePlateNumber: '',
        vehicleYear: '',
        vehiclePhoto: '',
        registrationCardPhotoFront: '',
        registrationCardPhotoBack: '',
        backgroundCheck: '',
        countryId: '',
        signature: '',
        verificationStatus: '0'
      },
      holaForm: {
        phone: '',
        driverType: '',
        avatarLink: '',
        firstName: '',
        lastName: '',
        nickname: '',
        birthDate: '',
        email: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      myProfile: 'auth/myProfile',
      myProfileLoading: 'auth/myProfileLoading',
      countries: 'countries/countries',
      driver: 'driver/currentItem',
      driverLoading: 'driver/loading',
      updatePhoneLoading: 'auth/updatePhoneLoading',
      updatePhoneErrors: 'auth/updatePhoneErrors'
    }),
    phoneErrors(){
      const errors = []
      if(this.updatePhoneErrors){
        if(this.updatePhoneErrors.status === 404){
          errors.push(this.$t(`validationError_phone_${this.updatePhoneErrors.data.data.type}`))
        }
        if(this.updatePhoneErrors.status === 422){
          errors.push(this.$t(`validationError_phone_${this.updatePhoneErrors.data.data.phone[0]}`))
        }
      }else{
        return []
      }
      return errors
    }
  },
  watch: {
    myProfile: {
      handler() {
        if (this.myProfile) {
          this.fillHolaForm(this.myProfile)
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.setForm()
    this.fetchCountries()
  },
  methods: {
    ...mapActions({
      fetchCountries: 'countries/getCountries',
      updateMyProfile: 'auth/updateProfile',
      updatePhone: 'auth/updatePhone',
      updateDriver: 'driver/update'
    }),
    async uploadMedia(base64){
      try {
        this.uploadMediaLoading = true
        return await apiMedia.upload(base64)
      }finally {
        this.uploadMediaLoading = false
      }
    },
    setForm() {
      const {profile, ...other} = this.driver
      Object.assign(this.form, other)
    },
    fillHolaForm(data) {
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'phone' && value && value.length >= 15) {
        }else{
          if (this.holaForm.hasOwnProperty(key)) this.holaForm[key] = value
        }
      })
    },
    async handleUpdatePhone(){
      await this.updatePhone({phone: this.holaForm.phone})
    },
    async handleUpdateMyProfile() {
      this.serverErrors = {}
      try {
        if (this.holaForm.avatarLink.includes('base64')) {
          await this.uploadMedia()
        }
        this.holaForm.accountTypes = [101]
        await this.updateMyProfile(this.holaForm)
      } catch (err) {
        if (err.errorType === "existence") {
          this.serverErrors = {
            [err.data.field]: this.$t(`serverError_${err.data.type}`)
          }
        } else {
          this.serverErrors = err?.data
        }
      }
    },
    async checkAndUploadFiles(){
      const uploadFieldProps = [
        'licensePhotoFront',
        'licensePhotoBack',
        'licensePhotoNFace',
        'idPhotoFront',
        'idPhotoBack',
        'backgroundCheck',
        'vehiclePhoto',
        'registrationCardPhotoFront',
        'registrationCardPhotoBack'
      ]
      for (const item of uploadFieldProps){
        if (this.form[item]?.includes('base64')) {
          this.form[item] = await this.uploadMedia(this.form[item])
        }
      }
    },
    async submitForm() {
      this.$refs.form.validate()
      if (this.validForm) {
        await this.handleUpdatePhone()
        await this.handleUpdateMyProfile()
        await this.checkAndUploadFiles()
        const requestPayload = {
          form: this.form,
          query: {
            driverId: this.form.id
          }
        }
       await this.updateDriver(requestPayload)
      }
    }
  }
}
</script>
<style lang="scss">
.driver-block {
  background: #f3f3f3;
  padding: 20px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
</style>
