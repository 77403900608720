import Vue from 'vue'

import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

const MOBILEREG = /^[0-9]*$/
const FLOAT_NUMBER = /^(0|[1-9]\d*)(\.\d+)?$/
const MOBILEPHONEREGEX = /^\+[0-9]*$/
const FROMMINTOMAXREGEX = /^[1-9][0-9]?$|^100$/
const SLUGREGEX = /^[a-zA-Z0-9_-]*$/

const COMMISSIONS_RANGES = /^[0-9][0-9]?$|^100$/

// eslint-disable-next-line
const URLREGEX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

export default Vue.mixin({
  data () {
    return {
      nameRules: [
        v => !!v || 'This field is required!',
        v => (v && v.length <= 64) || 'Name must be less than 64 characters',
        v => (v && v.length > 3) || 'Name must be more than 3 characters'
      ],
      packageNameRules: [
        v => !!v || 'This field is required!',
        v => (v && v.length < 20) || 'Name must be less than 20 characters',
        v => (v && v.length > 0) || 'Name must be more than 0 characters'
      ],
      defaultRules: [
        v => !!v || 'This field is required!'
      ],
      emailRules: [
        v => !!v || 'This field is required!',
        v => /.+@.+\..+/.test(v) || 'Enter a valid email'
      ],
      slugRules: [
        v => !!v || 'This field is required!',
        v => (SLUGREGEX.test(v)) || 'Enter only latin letters'
      ],
      required: [
        v => !!v || 'This field is required!'
      ],
      countryName: [
        v => !!v || 'This field is required!',
        v => (v && v.length < 50) || 'Name must be less than 50 characters'
      ],
      countryCode: [
        v => !!v || 'This field is required!',
        v => (v && v.length < 4) || 'Code must be less than 4 characters'
      ],
      limitRules: [
        v => !!v || 'This field is required!',
        v => (v && v > 0) || 'Enter a number greater than 0'
      ],
      descriptionRules: [
        v => !!v || 'This field is required!',
        v => (v && v.length < 300) || 'Name must be less than 300 characters'
      ],
      messageRules: [
        v => (v && v.length < 250) || 'Message must be less than 250 characters'
      ],
      discountRules: [
        v => !!v || 'This field is required!',
        v => (FROMMINTOMAXREGEX.test(v)) || 'Value must be integer and greater than or equal to 1 and less than or equal to 100'
      ],
      transactionFeeRules: [
        v => !!v || 'This field is required!',
        v => (COMMISSIONS_RANGES.test(v)) || 'Value must be integer and greater than or equal to 0 and less than or equal to 100'
      ],
      number: [
        v => (MOBILEREG.test(v)) || 'Value must be integer',
        v => !!v || 'This field is required!'
      ],
      floatNumber: [
        v => (FLOAT_NUMBER.test(v)) || 'Enter correct value',
        v => !!v || 'This field is required!'
      ],
      phoneRules: [
        v => (MOBILEREG.test(v)) || 'Enter valid phone'
      ],
      urlRules: [
        v => (URLREGEX.test(v)) || 'Enter valid link'
      ],
      phoneMaskRules: [
        v => (MOBILEPHONEREGEX.test(v)) || 'Enter valid mask'
      ],
      passwordRules: [
        v => !!v || 'This field is required!',
        v => (v && v.length <= 256) || 'Password must be less than 256 characters',
        v => (v && v.length > 4) || 'Password must be more than 4 characters'
      ],
      packagePrice: [
        v => (v >= 0 && v < 9999999) || 'Price must be more than 0 or 0 and less than 9999999'
      ],
      limitBDTLOffers: [
        v => !!v || 'This field is required!',
        v => (v && v < 9999999) || 'Limit must be less than 9999999',
        v => (v && v >= 0) || 'Limit must be more than 0 or 0'
      ],
      calloutsCountRules: [
        v => !!v || 'This field is required!',
        v => (v && v < 9999999999) || 'Callouts Count must be less than 9999999999',
        v => (v && v >= 0) || 'Callouts Count be more than 0 or 0'
      ],
      lifetimeRules: [
        v => !!v || 'This field is required!',
        v => (v && v < 9999999) || 'LifeTime must be less than 9999999',
        v => (v && v >= 0) || 'LifeTime be more than 0 or 0'
      ]
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
